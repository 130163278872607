import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
/** @jsxRuntime classic */
/* @jsx jsx */ import { css, jsx } from '@emotion/react'; /* eslint-disable-line */ /* eslint-disable-line */
import { userHasAccess } from "../util/hasAccess";

import registerAustralianStateRedux from 'app/resources/australianState';
const australianStateRedux = registerAustralianStateRedux('AUSTRALIAN_STATE_LIST', ['LIST']);

const containerCss = css`
	width: 200px;
	flex: 0 0 auto;
	height: 100%;
	background: #3d3d3d;
	position: relative;
	transition: width .4s;
	transform: translate3d(0,0,0);

	&.is-closed {
		width: 0;

		.wrap {
			transform: translateX(-100%);
		}
	}

	
	.sidemenu-trigger {
		background: #3d3d3d;
		font-size: 14px;
		padding: 10px 0;
		align-items: center;
		justify-content: center;
		width: 18px;
		height: 50px;
		left: 100%;
		z-index: 20;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		display: flex;		
		color: #fff;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		border: 0;

		> i {
			transition: transform .3s,-webkit-transform .3s;
		}
	}

	.wrap {
		width: 200px;
		overflow-y: auto;
		height: 100%;
		opacity: 1;
		visibility: visible;
		transition: transform .4s;
		transition: transform .4s,-webkit-transform .4s;

		> .logo {
			padding: 20px;

			> img {
				width: 100%;
				display: block;
			}
		}
	}

	.receival {
		button {
			span {
				font-size: 12px;
				font-weight: 800;
				text-transform: uppercase;
				color: #FFF;
			}
		}
	}

	.submenu {
		border-bottom: 1px solid #626262;
		
		&:last-of-type {
			border-bottom: none;
		}

		h5 {
			padding: 10px 20px;
			font-weight: 700;
			font-size: 14px;
			text-transform: uppercase;
			color: #fff;
			margin: 0;
		}

		a {
		    cursor: pointer;
			&.active {
				button {
					background: #202020;
				}
			}
		}

		button {
		    cursor: pointer;
			font-weight: 300;
			font-size: 14px;
			text-align: left;
			color: #b8b8b8;
			width: 100%;
			padding: 10px 20px;
			position: relative;
			border: 0;
			background: 0 0;
			outline: 0;

			&:hover {
				background: #202020;
			  }

			&::after {
				
				content: "\f054";
				display: inline-block;
				right: 15px;
				font-family: "Font Awesome 6 Pro",sans-serif;
				font-weight: 900;
				font-size: 8px;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				color: #fff;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}

			i {
				width: 16px;
				margin: 0 12px 0 0;
				text-align: center;
				color: #fff;
				vertical-align: top;
			}

			span {
				width: 124px;
				display: inline-block;
			}
		}

		button[data-notifications]::before {

			color: #fff;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			
			content: attr(data-notifications);
			width: 18px;
			height: 18px;
			padding-top: 5px;
			left: 8px;
			text-align: center;
			font-size: 11px;
			border-radius: 50%;
			z-index: 2;
			background: red;
		}

		button[data-notifications='0']::before {
			opacity: 0
		}
	}
	
	.hide {
		display: none;
	}
`;

const SiteNavigation = props => {

	const [showing, setShowing] = useState(true);

	const auth = useSelector(state => state.auth);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			australianStateRedux.actionCreators.list(auth, {

			})
		);
	}, []);

	return (
		<aside css={containerCss} className={showing ? "" : "is-closed"}>
			<button type="button" className="sidemenu-trigger js-sidemenu-trigger" onClick={() => setShowing(!showing)}>
				<i className="fas fa-chevron-circle-left"></i>
			</button>
			<div className="wrap">
				<div className="submenu">
					{userHasAccess(auth.user?.role, 'orders') && (
						<NavLink to={`/orders/dashboard`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Orders</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'orders') && (
						<NavLink to={`/orders/algorithm`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Algorithm</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'orders') && (
						<NavLink to={`/orders/promotion/all`}>
							<button data-notifications="0">
								<i className="fas fa-fire-alt"></i>
								<span>Promotions</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'orders') && (
						<NavLink to={`/orders/ingest`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Ingest</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'orders') && (
						<NavLink to={`/orders/ingest-report`}>
							<button data-notifications="0">
								<i className="fas fa-chart-bar"></i>
								<span>Ingest Report</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'stores') && (
						<NavLink to={`/s/stores`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Stores</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'stores') && (
						<NavLink to={`/s/vendors-schedule`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Vendor/Store Schedule</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'stores') && (
						<NavLink to={`/s/vendors`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Vendor/Store Orders</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'stores') && (
						<NavLink to={`/s/vendors-replen`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Vendor/Store Output</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'vendors') && (
						<NavLink to={`/v/schedule`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Vendor Schedule</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'vendors') && (
						<NavLink to={`/v/vendors`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Vendors</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'products') && (
						<NavLink to={`/products/p/status`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Products</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'products') && (
						<NavLink to={`/products/p/mins`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Product Mins</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'recipes') && (
						<NavLink to={`/products/r`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Recipes</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'shippers') && (
						<NavLink to={`/products/s`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Shippers</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'categories') && (
						<NavLink to={`/categories`}>
							<button data-notifications="0">
								<i className="fas fa-home"></i>
								<span>Categories</span>
							</button>
						</NavLink>
					)}
					{userHasAccess(auth.user?.role, 'users') && (
						<NavLink to={`/users`}>
							<button data-notifications="0">
								<i className="fas fa-users"></i>
								<span>Users</span>
							</button>
						</NavLink>
					)}
				</div>

				<div className="submenu">
					<NavLink to={`/auth/logout`}>
						<button data-notifications="0">
							<i className="fas fa-sign-out-alt"></i>
							<span>Logout</span>
						</button>
					</NavLink>
				</div>
			</div>
		</aside>
	);
}

export default SiteNavigation;