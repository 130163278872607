import * as React from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Loadable from 'react-loadable';
import { HashRouter } from 'react-router-dom';
import createStore from './store';

import App from './app/App';

// Create a store and get back itself and its history object
const { store } = createStore();

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
    <Provider store={store}>
        <HashRouter>
            <App />
        </HashRouter>
    </Provider>
);

const appRoot = document.getElementById('app-root');

if (appRoot.hasChildNodes() === true) {
	Loadable.preloadReady().then(() => {
		hydrateRoot(appRoot, Application);
	});
} else {
	const root = createRoot(appRoot);
	root.render(Application);
}