import * as React from 'react';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
/** @jsxRuntime classic */
/* @jsx jsx */ import { css, jsx } from '@emotion/react'; /* eslint-disable-line */ /* eslint-disable-line */

import Breadcrumb from './Breadcrumb';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import createPersistedState from 'use-persisted-state';

import Dropdown from 'app/components/Dropdown';

import registerAustralianStateRedux from 'app/resources/australianState';
const australianStateRedux = registerAustralianStateRedux('AUSTRALIAN_STATE_LIST', ['LIST']);

const headerCss = css`
	padding: 15px 40px 15px 20px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(178deg,#00adee 0,#002bbb 100%);
    box-shadow: 0 3px 7px 0 rgba(0,0,0,.35);
    display: flex;

	.logo {
		height: 30px;
        transform: scale(1.5);
        transform-origin: center center;
	}

    .state-selector{
        min-width: 200px;
    }
`;

const Header = props => {

    const pathname = props.location.pathname;
    const authedUsername = props.auth.user && props.auth.user.name;

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();
    
    const useBrowserState = createPersistedState('browserState');
    const [browserState, setBrowserState] = useBrowserState({selectedState: null});

	useEffect(() => {
		dispatch(
			australianStateRedux.actionCreators.list(auth, {

			})
		);
	}, []);

	const australianStates = useSelector(state => state.AUSTRALIAN_STATE_LIST);

    return (
        <header>
            <div css={headerCss}>
                <div>
                    <Link to="/">
                        <img className="logo" src="/assets/logo-bp-whitetext.png" alt="" />
                    </Link>
                </div>
                {australianStates.items && (
                    <Dropdown 
                        className="state-selector"
                        placeholder="Select State"
                        value={browserState?.selectedState?.abbr}
                        options={[
                            {value: "ALL", label: "All"},
                            ...australianStates?.items?.map(au_state => {return {value: au_state.abbreviation, label: au_state.name}})
                        ]}
                        onClick={value => {
                            const state = australianStates.items.find(au_state => au_state.abbreviation === value);
                            setBrowserState({selectedState: {abbr: value, id: state?.id ?? null}});
                        }}
                    />
                )}
            </div>
            {/* <Breadcrumb /> */}
        </header>
    );
}

export default Header;