import * as React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import Logout from './authentication/section/authentication/page/Logout';

const UserSection = Loadable({
    loader: () => import(/* webpackChunkName: "user-section" */ './users/Routes'),
    loading: () => null,
    modules: ['user-section'],
    webpack: () => [require.resolveWeak('./users/Routes')] // Prevents white flash
});

const OrdersSection = Loadable({
    loader: () => import(/* webpackChunkName: "order-section" */ './orders/Routes'),
    loading: () => null,
    modules: ['order-section'],
    webpack: () => [require.resolveWeak('./orders/Routes')] // Prevents white flash
});

const ProductsSection = Loadable({
    loader: () => import(/* webpackChunkName: "product-section" */ './products/Routes'),
    loading: () => null,
    modules: ['product-section'],
    webpack: () => [require.resolveWeak('./products/Routes')] // Prevents white flash
});

const CategoriesSection = Loadable({
    loader: () => import(/* webpackChunkName: "category-section" */ './categories/Routes'),
    loading: () => null,
    modules: ['category-section'],
    webpack: () => [require.resolveWeak('./categories/Routes')] // Prevents white flash
});

const VendorsSection = Loadable({
    loader: () => import(/* webpackChunkName: "vendor-section" */ './vendors/Routes'),
    loading: () => null,
    modules: ['vendor-section'],
    webpack: () => [require.resolveWeak('./vendors/Routes')] // Prevents white flash
});

const StoresSection = Loadable({
    loader: () => import(/* webpackChunkName: "store-section" */ './stores/Routes'),
    loading: () => null,
    modules: ['store-section'],
    webpack: () => [require.resolveWeak('./stores/Routes')] // Prevents white flash
});

export default ({ auth }) => {
    const navigate = useNavigate();
    return <Routes>
        <Route path="/users/*" element={<UserSection/>} />
        <Route path="/orders/*" element={<OrdersSection/>} />
        <Route path="/products/*" element={<ProductsSection/>} />
        <Route path="/categories/*" element={<CategoriesSection/>} />
        <Route path="/v/*" element={<VendorsSection/>} />
        <Route path="/s/*" element={<StoresSection/>} />
        <Route key="logout" path={`/auth/logout`} element={<Logout navigate={navigate}/>} />
        {/* <Route key="unauthorized" path={`/auth/unauthorized`} element={require('app/modules/authentication/page/Unauthorized').default} /> */}
        <Route path="/" element={<Navigate to={`/orders/dashboard`} />}/>
    </Routes>
}