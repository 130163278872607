let sharedData = {

};

export default function listActionCreators(resourceName, actionTypes, api, options) {
    let actionCreators = {

        list: function (auth, params, mergeKey, fetchAll) {
            return function (dispatch) {

                if (fetchAll) {
                    if (sharedData[resourceName]) {
                        return;
                    }

                    sharedData[resourceName] = true;
                }

                dispatch(actionCreators.request());

                return api.list(auth, params)
                    .then(
                        response => dispatch(response.errors ? actionCreators.failure(response.errors) : actionCreators.success(response, mergeKey)),
                        exception => dispatch(actionCreators.failure(exception))
                    ).then(
                        response => dispatch(actionCreators.complete()),
                        exception => dispatch(actionCreators.complete())
                    );
            }
        },
        
        request: function () {
            let name = resourceName + '_LIST_REQUEST';

            return {
                type: actionTypes[name]
            }
        },

        success: function (data, mergeKey) {
            let name = resourceName + '_LIST_SUCCESS';

            if (options.hasMeta) {
                return {
                    mergeKey: mergeKey,
                    meta: {
                        count: data.count,
                        limit: data.limit,
                        sort: data.sort,
                        offset: data.offset,
                        alphaPagination: data.alphaPagination
                    },
                    data: data.data,
                    type: actionTypes[name]
                }
            } else {
                return {
                    mergeKey: mergeKey,
                    data: data.data,
                    type: actionTypes[name]
                }
            }
        },

        complete: function () {
            let name = resourceName + '_LIST_COMPLETE';

            return {
                data: {},
                type: actionTypes[name]
            }
        },

        failure: function (errors) {
            let name = resourceName + '_LIST_FAILURE';

            return {
                error: errors,
                type: actionTypes[name]
            }
        },

		clear: function (error, data) {
			let name = resourceName + '_LIST_CLEAR';

			return {
				data: {},
				type: actionTypes[name]
			}
		},

		setItem: function (item) {
			let name = resourceName + '_LIST_SETITEM';

            console.log("SI", actionTypes, name, {
				data: {
					item: item
				},
				type: actionTypes[name]
			})

			return {
				data: {
					item: item
				},
				type: actionTypes[name]
			}
		}
    };

	return {
		list: actionCreators.list,
		listClear: actionCreators.clear,
		listSetItem: actionCreators.setItem
	};
}