import * as React from 'react';

/* @jsxImportSource @emotion/react */  import { css, jsx } from '@emotion/react'; /* eslint-disable-line */

const style = css`

.ui-dropdown {
    display: inline-block;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    border-radius: 4px 4px 4px 4px;
    border: 1px solid #e1e1e1;
    padding: 12px 20px 12px 20px;
    outline: none;
    width: 100%;
    color: #555555;
    background-color: #fff;
}

.ui-widget, .ui-widget * {
    width: 100%;
    box-sizing: border-box;
}

.ui-dropdown label.ui-dropdown-label {
    cursor: pointer;
}

.ui-dropdown-label {
    display: block;
    border: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    flex-grow: 1;
}

.ui-inputtext {
    top: 0!important;
    border: none!important;
    margin: 0;
    outline: 0;
}

.placeholder {
    opacity: .7;
}

.ui-dropdown-trigger {
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    cursor: pointer;
    width: initial;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 .25em;
}

.ui-dropdown-content {
    display: flex;

    span {
        width: 10px;
    }
}

.ui-dropdown-panel {
    min-width: 100%;
    position: absolute;
    height: auto;
    display: none;
    min-width: 100%;
}

.ui-shadow {
    -webkit-box-shadow: 0 1px 3px 0 rgba(0,0,0,.3);
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.3);
}

.ui-dropdown-list {
    border: 0;
    background: #FFF;
}

.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.ui-dropdown-item {
    border: 0;
    cursor: pointer;
    margin: 1px 0;
    padding: .25em .25em;
    text-align: left;

    &:hover {
        background: #F4F4F4;
    }
}

.ui-dropdown-items-wrapper {
    overflow: auto;
}

`;

export default class Dropdown extends React.Component {

    constructor() {
        super();

        this.state = {
            showing: false
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleCancel, false);
        document.addEventListener('touchstart', this.handleCancel, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleCancel, false);
        document.removeEventListener('touchstart', this.handleCancel, false);
    }

    handleCancel = (ev) => {

        if (this.node && this.node.contains(ev.target)) {
            return;
        }

        if (this.state.showing) {
            this.setState({
                showing: false
            });
        }

    }

    handleClick = (ev) => {

        this.setState({
            showing: !this.state.showing
        });

    }

    handleOptionClick = (val, ev) => {
        this.props.onClick(val);
        return;
    }

    render() {

        const offset = this.props.maxOffset ? this.props.maxOffset : 1000;

        const options = [];
        let selectedLabel = "";

        if (!this.props.options) {
            return <div />
        }

        this.props.options.forEach(x => {

            if (this.props.value === x.value) {
                selectedLabel = x.label;
            }

            options.push(<li key={x.value} onClick={this.handleOptionClick.bind(null, x.value)} className="ui-dropdown-item ui-corner-all ui-state-highlight">
                <span>{x.label}</span>
            </li>);
        })

        let topPx = 0;
        if (options.length > offset) {
            topPx = (options.length - 3) * -34;
        }

        return <div css={style} onClick={this.handleClick}>
            <div ref={node => this.node = node} className={(this.props.className ? this.props.className : '') + " ui-dropdown ui-widget ui-state-default ui-corner-all ui-helper-clearfix ui-dropdown-open"} >

                <div className="ui-dropdown-content">
                    <label className={"ui-dropdown-label ui-inputtext ui-corner-all"+(selectedLabel ? "" : " placeholder")}>{selectedLabel ? selectedLabel : this.props.placeholder}</label>
                    <span className="ui-clickable fa fa-fw fa-caret-down"></span>
                </div>

                {this.state.showing && <div className="ui-dropdown-panel ui-widget-content ui-corner-all ui-shadow" style={{ display: "block", zIndex: 1001, top: topPx + 'px', left: 0 }}>

                    <div className="ui-dropdown-items-wrapper" style={{ maxHeight: "250px" }}>
                        <ul className="ui-dropdown-items ui-dropdown-list ui-widget-content ui-widget ui-corner-all ui-helper-reset">
                            {options}
                        </ul>
                    </div>
                </div>}
            </div>
        </div>
    }

}