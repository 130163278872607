import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as logoutAC from 'app/modules/authentication/action/Logout';

class LogoutPage extends React.Component {

    componentDidMount() {
        this.props.actions.logoutUser(this.props.auth, this.props.navigate);
    }

    render() {
        return (
            <div>
                <span id="authentication-container" />
            </div>
        );
    }
}

LogoutPage.contextTypes = {

};

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(logoutAC, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);