// The basics
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
/** @jsxRuntime classic */
/* @jsx jsx */ import { css, jsx } from '@emotion/react'; /* eslint-disable-line */ /* eslint-disable-line */
import AppRoutes from './modules/Routes';
import SiteNavigation from './components/SiteNavigation';
import SiteHeader from './components/siteHeader/Header';

import * as verifyTokenAC from './modules/authentication/action/VerifyToken';
import * as searchParamsAC from './modules/authentication/action/SearchParams';
import AuthCheck from './modules/authentication/component/AuthCheck';
import Loadable from 'react-loadable';

import ErrorBoundary from 'app/components/ErrorBoundary';
import Cookies from 'js-cookie';
import usePrevious from 'app/hooks/usePrevious';
import { Routes, Route, Navigate, useNavigate, useParams, useLocation } from 'react-router-dom';

import config from 'app/config';

const Authentication = Loadable({
    loader: () => import(/* webpackChunkName: "authentication" */ './modules/authentication/Routes'),
    loading: () => null,
    modules: ['authentication'],
    webpack: () => [require.resolveWeak('./modules/authentication/Routes')] // Prevents white flash
});


const scCss = css`
    display: flex;
    height: calc(100vh - 64px);
    overflow: hidden;
`;

const pageMainCss = css`
    flex: 1 1 auto;
    padding: 20px 40px 0 30px;
    background: #f0f0f0;
    overflow-y: auto;


		.modal-close{
			display: none;
		}

		.modal {

			> div:not(:first-of-type):last-of-type:not(:empty) {
				position: fixed;
				top: 0;
				left: 0;
				z-index: 1060;
				width: 100%;
				height: 100%;
				overflow-x: hidden;
				overflow-y: auto;
				outline: 0;
				background: rgba(0, 0, 0, 0.3);


				.modal-close {
					display: block;
					position: absolute;
					top: 0;
					right: 0;
					padding: 10px;
					
					i {
						font-size: 2.5rem;
						color: black;
					}
				}


				> div:not(:empty) {
					position: absolute;

					right: 0;
					height: 95vh;

					animation-duration: .5s;
					animation-fill-mode: both;
					animation-delay: 0.1s;
					border-radius: .475rem;
			
										
					width: 90%;
					max-width: 90%;
					
					
					background: #fff;
					padding: 30px;
				}

				> div.site-nav {
					margin-left: 320px;
				}
			}

		}	


		.modal.site-nav {
			
			> div:not(:first-of-type):last-of-type:not(:empty) > div {
					max-width: 80% !important;
					width: 80% !important;
					margin-left: 320px;
			}
		}
`;

const publicCss = css`
    width: 100%;
    height: 100%;
`

class App extends React.Component {

    componentDidMount() {
        this.props.vtActions.verifyToken(Cookies.get(config.cookieName));
        this.props.spActions.decodeSearchParams(this.props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.search !== prevProps.location.search) {
            this.props.spActions.decodeSearchParams(this.props);
        }

        if (this.props.auth.isAuthenticated && this.props.searchParams.hasDecoded && this.props.location.pathname === '/') {
            this.props.router.navigate({ pathname: '/orders/dashboard' });
        }

        if (this.props.auth.isAuthenticated && this.props.searchParams.hasDecoded && this.props.searchParams.nextPath) {
            this.props.router.navigate({ pathname: this.props.searchParams.nextPath });
        }
    }

    render() {

        const props = this.props;

        const renderSiteHeader = props.location.pathname.startsWith('/special') ? false : true;
        const readerSiteNav = props.location.pathname.startsWith('/special') ? false : true;

        if (!props.auth.isAuthenticated && !props.auth.didAttemptTokenVerification) {
            return <AuthCheck />
        }

        if (!props.auth.isAuthenticated) {
            return <div css={publicCss}>
                <Routes>
                    <Route path="/auth/*" element={<Authentication/>} />
                    <Route path="*" element={<Navigate to={`/auth/login?nextPath=${props.location.pathname}`} />}/>
                </Routes>
            </div>
        }

        return (
            <div id="app">
                <ErrorBoundary>
                    {renderSiteHeader && <SiteHeader auth={this.props.auth} location={this.props.location} />}
                    <div id="site-container" css={readerSiteNav ? scCss : null}>
                        {readerSiteNav && <SiteNavigation auth={this.props.auth} location={this.props.location} />}
                        <main id="page-main" css={readerSiteNav ? pageMainCss : publicCss}>
                            <div className="modal">
                                <AppRoutes auth={this.props.auth} />
                            </div>
                        </main>
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    searchParams: state.searchParams
});

const mapDispatchToProps = (dispatch) => ({
    vtActions: bindActionCreators(verifyTokenAC, dispatch),
    spActions: bindActionCreators(searchParamsAC, dispatch)
});

function withRouter(Component) {
	function ComponentWithRouterProp(props) {

		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();

		const currentLocation = location.pathname;
		const prevLocation = usePrevious(currentLocation);

		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
				location={location}
				previousLocationPath={prevLocation}
			/>
		);
	}

	return ComponentWithRouterProp;
}

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
